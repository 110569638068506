/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { OrgDetails } from "../Components/Details";
import { PrimaryButton, Button, ConfirmationBox } from "../../../components";
import {
  orgActions,
  getOrgDetailsById,
  isAdmin,
  isOrganizer,
  isArchitect,
  getCurrentUser,
  getResetCaptcha,
} from "../../../store";
import { OrgReset } from "../Components/OrgReset";

export default () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ showEnable: false });
  const handleEdit = () => {
    navigate(`/O/${org.id}/edit`);
  };
  const handleUserUpdate = (userId) => {
    navigate(`/O/${org.id}/user/${userId}/edit`);
  };
  const handleReEnable = (val) => {
    setState((_) => ({ ..._, showEnable: val }));
  };
  const handleReEnableOrg = () => {
    dispatch(
      orgActions.enableOrDisableOrg({ orgId: orgId, enabling: org.is_disabled })
    );
    handleReEnable(false);
  };

  return (
    <div className="org-int-det w-100 h-100 oy-auto">
      <Grid container spacing={0} direction="row-reverse">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="btn-sec">
            <div style={{ marginLeft: "150px" }}>
              <PrimaryButton
                text="Edit Details"
                className="w-100 btn-edit"
                onClick={handleEdit}
              />
            </div>
            <div style={{ width: "30px" }}></div>
            {org.is_disabled && (
              <Button
                color="#00CC55"
                disableElevation
                text="Re-Enable"
                className="btn-enable"
                onClick={() => handleReEnable(true)}
              />
            )}
          </div>
          {Boolean(org) && (
            <OrgDetails
              org={org}
              showEdit={false}
              showTitle={false}
              onUpdateUser={handleUserUpdate}
              enableEditOrgUser={
                isAdmin(user) || isOrganizer(user) || isArchitect(user)
              }
            />
          )}
        </Grid>
      </Grid>
      {state.showEnable && (
        <ConfirmationBox
          closeOnEnter
          row={false}
          className=""
          titleClassName="c00CC55"
          btnCancel={{
            onClick: () => handleReEnable(false),
            text: "Go back",
            type: "link",
          }}
          btnOK={{
            text: "Activate",
            color: "#00CC55",
            onClick: handleReEnableOrg,
          }}
          title="Activate Organisation"
          subtitle="Activating the organisation will allow all permitted actions to be taken within Fusion"
        />
      )}
    </div>
  );
};
