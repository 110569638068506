import React, { useEffect, useRef } from "react";
import {
  AppNavBar,
  AppErrorBoundry,
  ConfirmationBox,
  OvalLoading,
  ToastAlert,
  useSub,
} from "../../components";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getModuleSwitchKey,
  getCurrentUser,
  userActions,
  orgActions,
  commonAction,
  getAlert,
  isLoggedOut,
  isAdmin,
  isOrganizer,
  isAccessor,
  isArchitect,
  isAuditee,
  getFullLoading,
  getCurrentUserOrg,
} from "../../store";

const MODULE_ID = "console";
const SettingsMenu = { link: "/T", label: "Setting" };
const NavItems = [
  { link: "/O", label: "Organisation" },
  // { link: '/M', label: 'Mapping' },
  { link: "/U", label: "User" },
  { link: "/S", label: "SSO" },
];
export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(getCurrentUser);
  const org = useSelector(getCurrentUserOrg);
  const notification = useSelector(getAlert);
  const loggedOut = useSelector(isLoggedOut);
  const fullLoading = useSelector(getFullLoading);
  const _timerRef = useRef();

  const getUserNavItems = () => {
    if (org === "NotExist") {
      return [NavItems[0]];
    } else if (isAdmin(user) || isArchitect(user)) {
      return [...NavItems, SettingsMenu];
    } else if (isOrganizer(user) || isAccessor(user)) {
      return [...NavItems];
    } else if (isAuditee(user)) {
      return [NavItems[0]];
    } else {
      return [];
    }
  };
  const gotoLogin = () => {
    let path = "/login";
    if (location.pathname !== "/") {
      path += `${location.search ? location.search + "&" : "?"}next=${
        location.pathname
      }`;
    }
    navigate(path);
  };
  useEffect(() => {
    document.title = "Console | Fusion Demo";
    const token = getModuleSwitchKey();
    if (token === null || token === "null") {
      gotoLogin();
    } else if (user === null && token) {
      dispatch(userActions.validateToken({ token: token }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (user === "Error") {
        gotoLogin();
        return;
      }
      dispatch(orgActions.getUserOrg());
      dispatch(orgActions.fetchIndustryList());
      dispatch(orgActions.fetchCountryList());
      dispatch(orgActions.fetchDepartments());
      dispatch(orgActions.fetchDesignations());
      dispatch(userActions.fetchRoles());
      dispatch(userActions.fetchCountryCodes());
    }
  }, [user]);
  useEffect(() => {
    if (org === "NotExist") {
      navigate("/O/add");
    }
  }, [org]);
  useEffect(() => {
    if (notification) {
      let autoClose =
        notification.autoClose === undefined ? true : notification.autoClose;
      if (autoClose) {
        _timerRef.current = setTimeout(closeConfirmation, 3000);
      }
    }
  }, [notification]);
  useEffect(() => {
    if (loggedOut) {
      dispatch(userActions.clear(null));
      navigate("/login", { replace: true });
    }
  }, [loggedOut]);

  useSub("SESSION_LOGOUT", () => {
    dispatch(userActions.logout());
  });
  const closeConfirmation = () => {
    if (_timerRef.current) {
      clearTimeout(_timerRef.current);
    }
    // if (notification && notification.logoutSession)  {
    //   dispatch(userActions.logout());
    // }
    dispatch(commonAction.setAlert(null));
  };
  const handleProfile = () => {
    dispatch(userActions.fetchUserSuccess(null));
    navigate("/profile");
  };
  return (
    <div className="col w-100 h-100 screen">
      {Boolean(user && user !== "Error" && org) ? (
        <>
          <AppNavBar
            currentModule={MODULE_ID}
            isAdmin={isAdmin(user)}
            isArchitect={isArchitect(user)}
            user={user}
            items={getUserNavItems()}
            className="app-nav"
            onProfile={handleProfile}
          />
          <main className="col flex-full main">
            <AppErrorBoundry>{props.children}</AppErrorBoundry>
          </main>
          {Boolean(notification) && (
            <React.Fragment>
              {notification.severity === "success" ? (
                <ConfirmationBox
                  {...notification}
                  btnOK={{ onClick: closeConfirmation }}
                />
              ) : (
                <ToastAlert
                  title={notification.title}
                  onClose={closeConfirmation}
                />
              )}
            </React.Fragment>
          )}
          {fullLoading && (
            <div className="overlay-load col v-ctr h-ctr">
              <CircularProgress sx={{ color: "#FFF" }} />
            </div>
          )}
        </>
      ) : (
        <OvalLoading />
      )}
      {props.isLoading && <OvalLoading />}
    </div>
  );
};
